<template>
    <div class="mt-2">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-card>
          <b-row>
            <!-- Table Top -->
            <b-col xl="12" md="12" sm="12" cols="12">
              <b-row class="mb-2" align-h="end">
                <!-- Per Page -->
                <!-- <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <label>Show</label>
                  <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                  <label>inputs</label>
                </b-col> -->
  
                <b-col cols="12" md="4">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input style="width:100%" id="filter-input" v-model="filter" class="d-inline-block mr-1" type="search" placeholder="Search..." />
                    <!-- <b-button style="width:50%" @click="calculateInput()" class="float-right" variant="success">
                      Create Calculate Variables
                    </b-button> -->
                  </div>
                </b-col>
              </b-row>
  
              <b-table
                striped
                hover
                ref="refSurveysTable"
                class="position-relative"
                :items="calculateInputs"
                :filter="filter"
                :filter-included-fields="filterOn"
                filter-debounce="100"
                selectable
                select-mode="single"
                responsive
                :small="false"
                head-variant="light"
                :fields="inHeaders"
                show-empty
                empty-text="No matching records found"
                @row-selected="onRowSelected"
                :current-page="currentPage"
                :per-page="perPage"
              >
              <template #cell(id)="data">
                  <span class="font-weight-bold">
                    <strong class="text-primary"> #{{ data.item.id }} </strong>
                  </span>
                </template>
                <template #cell(calculateName)="data">
                  <span class="font-weight-bold">
                    {{ data.item.calculateName ? data.item.calculateName : 'N/A' }}
                  </span>
                </template>
                <template #cell(status)="data">
                  <b-badge v-if="data.item.status == 'B'" pill :variant="`light-success`" class="text-capitalize">
                    Complete
                  </b-badge>
  
                  <b-badge v-if="data.item.status == 'A'" pill :variant="`light-warning`" class="text-capitalize">
                    Draft
                  </b-badge>
                </template>
  
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>
                    <b-dropdown-item
                      :to="{
                        name: 'calculate-detail',
                        params: { id: data.item.id },
                      }"
                    >
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50">Details </span>
                    </b-dropdown-item>
  
                    <b-dropdown-item @click="deleteOutput(data.item.id)">
                      <feather-icon icon="DeleteIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
  
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                  </b-col>
  
                  <!-- Pagination -->
                  <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
    </div>
  </template>
  
  <script>
  // import { BFormFile } from "bootstrap-vue";
  import { BImg, BPagination, BFormRating, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
  import axiosIns from '@/libs/axios';
  import store from '@/store';
  import router from '@/router';
  import { onUnmounted } from '@vue/composition-api';
  import formulStoreModule from '../formulStoreModule';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import moment from 'moment';
  import _ from 'lodash';
  import vSelect from 'vue-select';
  import Ripple from 'vue-ripple-directive';
  
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  
  export default {
    components: {
      BImg,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BOverlay,
      BFormGroup,
      BPagination,
      BFormRating,
      moment,
      vSelect,
      flatPickr,
  
      ValidationProvider,
      ValidationObserver,
    },
    mounted() {
      this.getOutputs();
    },
    setup() {
      const FORMUL_APP_STORE_MODULE_NAME = "formul";
      // Register module
      if (!store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
        store.registerModule(FORMUL_APP_STORE_MODULE_NAME, formulStoreModule);
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
          store.unregisterModule(FORMUL_APP_STORE_MODULE_NAME);
      });
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        filter: null,
        filterOn: ['calculateName'],
        perPage: localStorage.getItem('calculateShow') ?? 10,
        loading: false,
        where: router.currentRoute.name,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        totalRows: 0,
        calculateInputs: [],
        inHeaders: [
          {
            key: 'id',
            label: '',
            sortable: true,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            key: 'calculateName',
            label: 'Title',
            sortable: true,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
  
          {
            key: 'status',
            label: 'Status',
            sortable: true,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            key: 'actions',
            label: 'ACTIONS',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
      };
    },
    computed: {
      dataMeta() {
        return {
          from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
          to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
          of: this.totalRows,
        };
      },
    },
    methods: {
      dateFormat(val) {
        if (val != undefined) {
          return moment(String(val)).format('MM-DD-YYYY');
        }
      },
      calculateInput() {
        router.push({ name: 'calculate-input-variables' });
      },
      onRowSelected(item) {
        if (this.$Can('see_output_detail')) {
          router.push({
            name: 'calculate-detail',
            params: { id: item[0].id },
          });
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      getOutputs() {
        this.loading = true;
        store
          .dispatch('formul/fetchCalculatedData')
          .then((res) => {
            this.totalRows = res.data.length
            this.calculateInputs = res.data
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      deleteOutput(id) {
        const self = this;
        this.$swal({
          title: 'Are you sure you want to delete the calculated variables?',
          // text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
          customClass: {
              confirmButton: "btn btn-success mr-1",
              cancelButton: "btn btn-danger",
              denyButton: "btn btn-primary",
            },
        }).then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch('formul/deleteOutput', id)
              .then((res) => {
                self.getOutputs();
                self.$swal.fire('Deleted!', 'Input Variables has been deleted.', 'success');
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      },
    },
    watch: {
      perPage: {
        handler: function(val, before) {
          localStorage.setItem('calculateShow', val);
        },
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  